<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {
    this.$auth.logout()
  },

  render: () => null,

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
