<template lang="pug">
#users-view.app-view
  BackButton

  .av-container
    h1.av-title Manage Users

    .av-block
      button.create-action(@click.prevent="createUser()") Create User

      vue-good-table(:columns="columns" :rows="usersFullNames" :fixed-header="!isCompact" styleClass="vgt-table")
        template(slot='table-row', slot-scope='props')
          span(v-if="props.column.field == 'email'")
            span(:title="props.row.email") {{ props.row.email }}
          span(v-else) {{props.formattedRow[props.column.field]}}
          button.edit-user-button.small(v-if="props.column.field == 'action'" @click="editUser(props.row)") Edit
</template>

<script>
import { parseISO, formatISO } from 'date-fns'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { mapState } from 'vuex'

export default {
  props: {},

  data() {
    return {
      // ...mapState({ users: state => state.portalUser.users }),
      // users: [... this.$store.state.portalUser.users],
      columns: [
        {
          label: 'Name',
          field: 'fullName',
        },
        {
          label: 'Email',
          field: 'email',
          tdClass: 'uv-email-column',
        },
        {
          label: 'Order Count',
          field: 'ordersCount',
          type: 'number',
        },
        {
          label: 'Created On',
          field: this.formateDate,
          thClass: 'vgt-right-align',
          tdClass: 'vgt-right-align',
        },
        {
          label: 'Actions',
          field: 'action',
          sortable: false,
          thClass: 'vgt-center-align',
          tdClass: 'vgt-center-align',
        },
      ],
    }
  },

  computed: {
    ...mapState({ users: state => state.portalUser.users }),
    isCompact,
    usersFullNames,
  },

  watch: {
    '$app.carrier': {
      immediate: true,
      async handler(newValue) {
        this.fetchRecords()
      },
    },
  },

  methods: {
    createUser,
    editUser,
    formateDate,
    fetchRecords,
  },

  components: {
    BackButton: require('@/components/BackButton').default,
    VueGoodTable,
  },
}

/* Computed ---------------------------------------------------- */
function isCompact() {
  return this.$breakpoint.isPhone || this.$breakpoint.isMobile || this.$breakpoint.isTablet
}

function usersFullNames() {
  const new_users = this.users.map(u => {
    const user = u
    user.fullName = `${u.firstName} ${u.lastName}`
    return user
  })

  // deep copy
  return JSON.parse(JSON.stringify(new_users))
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function createUser() {
  this.$panel.open('CreateUser')
}

function editUser(user) {
  console.log(user)
  this.$panel.open('EditUser', { user })
}

function formateDate(rowObj) {
  return formatISO(parseISO(rowObj.createdAt), { representation: 'date' })
}

async function fetchRecords() {
  await this.$store.dispatch('portalUser/fetch', { page: 1, sort: 'asc' })
}
</script>
