<template lang="pug">
  #dashboard
    h1 Welcome!
    .last-login Last logged in: {{ lastLoginTime }}
    .nav-blocks
      a.nav-block(@click="gotoRoute('createOrder')")
        icon(data='~@icon/order-create.svg' :fill="false")
        span Create <br> Order
      a.nav-block(@click="gotoRoute('createBatchOrder')")
        icon.create-batch(data='~@icon/boxes.svg' :fill="false" width="95" height="64")
        span Create <br> Batch Order
      a.nav-block(@click="gotoRoute('viewOrders')")
        icon(data='~@icon/order-status.svg' :fill="false" width="70" height="70")
        span Order <br> Status

    .admin(v-if="$app.user.admin")
      h2 Admin
      .nav-blocks
        a.nav-block(@click="gotoRoute('usersView')")
          icon(data='~@icon/users.svg' :fill="false" width="70" height="70")
          span Manage <br> Users

        a.nav-block(@click="gotoRoute('carriersView')")
          icon(data='~@icon/carrier.svg' :fill="false" width="70" height="70")
          span Manage <br> Carriers

        //- a.nav-block(@click="gotoRoute('adminViewOrders')")
        //-   icon(data='~@icon/order-status.svg' :fill="false" width="70" height="70")
        //-   span Manage <br> Orders
</template>

<script>
import { formatRelative, parseISO } from 'date-fns'

export default {
  props: {},

  data() {
    return {}
  },

  computed: {
    lastLoginTime,
  },

  watch: {},

  methods: {
    gotoRoute,
  },

  components: {},

  metaInfo() {
    return {
      title: 'Home',
    }
  },
}

/* Computed ---------------------------------------------------- */
function lastLoginTime() {
  return formatRelative(parseISO(this.$app.user.lastSeenAt), new Date())
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function gotoRoute(routeName) {
  this.$router.push({ name: routeName })
}
</script>
