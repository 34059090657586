export const state = {
  panels: [],
}

export const getters = {}

export const mutations = {
  OPEN(state, panel) {
    const index = state.panels.findIndex(m => m.componentPath == panel.componentPath)

    if (index == -1) state.panels.push(panel)
  },

  CLOSE(state, componentPath) {
    const index = state.panels.findIndex(m => m.componentPath == componentPath)

    if (index !== -1) state.panels.splice(index, 1)
  },

  CLOSE_ALL(state) {
    state.panels = []
  },
}

export const actions = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
