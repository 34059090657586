import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import { getInstance } from '@/plugins/auth0-plugin'
import store from '@/store'

Vue.use(VueAxios, axios)

Vue.axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/portal`
Vue.axios.defaults.headers.common['Accept'] = 'application/json'

export default function setup() {
  Vue.axios.interceptors.request.use(
    async config => {
      const instance = getInstance()

      await instance.getTokenSilently().then(authToken => {
        config.headers.AuthType = 'bearer'
        config.headers.Authorization = `Bearer ${authToken}`
        config.headers.Carrier = store.state.carriers.current?.id
      })

      return config
    },
    function(err) {
      return Promise.reject(err)
    }
  )

  Vue.axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      const { data, status } = error.response

      // if (status === 401 && ['Unauthorized auth'].indexOf(data.error) > -1) {
      //   window.location = '/logout'
      // }

      return Promise.reject(error)
    }
  )
}
