import BaseModel from './base'

class Patient extends BaseModel {
  constructor(options) {
    const intialId = options?.id ? options.id : BaseModel.uuidv4()

    const defaults = {
      id: intialId,
    }

    super(options, { ...defaults, ...Patient.defaultOptions() })

    this._fromConfig = this.constructor.fromConfig()
  }

  static defaultOptions() {
    return {
      firstName: null,
      lastName: null,
      birthDate: null,
      phone: null,
      email: null,
      line: null,
      line1: null,
      city: null,
      state: null,
      postalCode: null,
      sex: null,
      sku: null,
    }
  }

  static fromConfig() {
    return {
      firstName: {
        component: 'BaseInput',
        label: 'First Name',
        halfWidth: true,
        validations: {
          required: {
            params: null,
            message: 'First Name is required',
          },
        },
      },
      lastName: {
        component: 'BaseInput',
        label: 'Last Name',
        halfWidth: true,
        validations: {
          required: {
            params: null,
            message: 'Last Name is required',
          },
        },
      },
      birthDate: {
        component: 'BaseInput',
        label: 'Date of Birth',
        placeholder: 'MM/DD/YYYY',
        mask: '##/##/####',
        halfWidth: true,
        validations: {
          required: {
            params: null,
            message: 'Birthdate is required',
          },
          validBirth: {
            params: null,
            message: 'Not a valid birthdate',
          },
        },
      },
      sex: {
        component: 'BaseSelect',
        label: 'Sex',
        halfWidth: true,
        options: [
          {
            label: 'Female',
            value: 'F',
          },
          {
            label: 'Male',
            value: 'M',
          },
        ],
        validations: {
          required: {
            params: null,
            message: 'Sex is required',
          },
        },
      },
      phone: {
        component: 'BaseInput',
        label: 'Cell Phone',
        mask: '(###) ###-####',
        placeholder: '(555) 555-5555',
        validations: {
          required: {
            params: null,
            message: 'Cell phone is required',
          },
          validPhone: {
            params: null,
            message: 'Not a valid cell phone',
          },
        },
      },
      email: {
        component: 'BaseInput',
        label: 'Email',
        placeholder: 'me@example.com',
        validations: {
          required: {
            params: null,
            message: 'Email is required',
          },
          email: {
            params: null,
            message: 'Not a valid email address',
          },
        },
      },
      line: {
        component: 'BaseInput',
        label: 'Address 1',
        placeholder: 'Street Address',
        validations: {
          required: {
            params: null,
            message: 'Street address is required',
          },
        },
      },
      line1: {
        component: 'BaseInput',
        label: 'Address 2',
        placeholder: '(Optional)',
        validations: {},
      },
      city: {
        component: 'BaseInput',
        label: 'City',
        validations: {
          required: {
            params: null,
            message: 'A city is required',
          },
        },
      },
      state: {
        component: 'BaseSelect',
        label: 'State',
        halfWidth: true,
        options: [
          {
            label: 'Alabama',
            value: 'AL',
          },
          {
            label: 'Alaska',
            value: 'AK',
          },
          {
            label: 'Arizona',
            value: 'AZ',
          },
          {
            label: 'Arkansas',
            value: 'AR',
          },
          {
            label: 'California',
            value: 'CA',
          },
          {
            label: 'Colorado',
            value: 'CO',
          },
          {
            label: 'Connecticut',
            value: 'CT',
          },
          {
            label: 'Delaware',
            value: 'DE',
          },
          {
            label: 'District Of Columbia',
            value: 'DC',
          },
          {
            label: 'Florida',
            value: 'FL',
          },
          {
            label: 'Georgia',
            value: 'GA',
          },
          {
            label: 'Hawaii',
            value: 'HI',
          },
          {
            label: 'Idaho',
            value: 'ID',
          },
          {
            label: 'Illinois',
            value: 'IL',
          },
          {
            label: 'Indiana',
            value: 'IN',
          },
          {
            label: 'Iowa',
            value: 'IA',
          },
          {
            label: 'Kansas',
            value: 'KS',
          },
          {
            label: 'Kentucky',
            value: 'KY',
          },
          {
            label: 'Louisiana',
            value: 'LA',
          },
          {
            label: 'Maine',
            value: 'ME',
          },
          {
            label: 'Maryland',
            value: 'MD',
          },
          {
            label: 'Massachusetts',
            value: 'MA',
          },
          {
            label: 'Michigan',
            value: 'MI',
          },
          {
            label: 'Minnesota',
            value: 'MN',
          },
          {
            label: 'Mississippi',
            value: 'MS',
          },
          {
            label: 'Missouri',
            value: 'MO',
          },
          {
            label: 'Montana',
            value: 'MT',
          },
          {
            label: 'Nebraska',
            value: 'NE',
          },
          {
            label: 'Nevada',
            value: 'NV',
          },
          {
            label: 'New Hampshire',
            value: 'NH',
          },
          {
            label: 'New Jersey',
            value: 'NJ',
          },
          {
            label: 'New Mexico',
            value: 'NM',
          },
          {
            label: 'New York',
            value: 'NY',
          },
          {
            label: 'North Carolina',
            value: 'NC',
          },
          {
            label: 'North Dakota',
            value: 'ND',
          },
          {
            label: 'Ohio',
            value: 'OH',
          },
          {
            label: 'Oklahoma',
            value: 'OK',
          },
          {
            label: 'Oregon',
            value: 'OR',
          },
          {
            label: 'Pennsylvania',
            value: 'PA',
          },
          {
            label: 'Rhode Island',
            value: 'RI',
          },
          {
            label: 'South Carolina',
            value: 'SC',
          },
          {
            label: 'South Dakota',
            value: 'SD',
          },
          {
            label: 'Tennessee',
            value: 'TN',
          },
          {
            label: 'Texas',
            value: 'TX',
          },
          {
            label: 'Utah',
            value: 'UT',
          },
          {
            label: 'Vermont',
            value: 'VT',
          },
          {
            label: 'Virgin Islands',
            value: 'VI',
          },
          {
            label: 'Virginia',
            value: 'VA',
          },
          {
            label: 'Washington',
            value: 'WA',
          },
          {
            label: 'West Virginia',
            value: 'WV',
          },
          {
            label: 'Wisconsin',
            value: 'WI',
          },
          {
            label: 'Wyoming',
            value: 'WY',
          },
        ],
        validations: {
          required: {
            params: null,
            message: 'This field is required',
          },
        },
      },
      postalCode: {
        component: 'BaseInput',
        label: 'Zip Code',
        mask: '#####',
        halfWidth: true,
        validations: {
          required: {
            params: null,
            message: 'A zip code is required',
          },
        },
      },
      sku: {
        component: 'BaseSelect',
        label: 'Sku',
        halfWidth: true,
        validations: {
          required: {
            params: null,
            message: 'A SKU is required',
          },
          validateSku: {
            params: null,
            message: 'Not a valid SKU',
          },
        },
      },
    }
  }
}

export { Patient as default }
