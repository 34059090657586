import BaseModel from './base'
import Carrier from './carrier'
class PortalUser extends BaseModel {
  constructor(options, excludes) {
    const defaults = {
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      carrierId: null,
      createdAt: null,
      ordersCount: 0,
      admin: false,
      // keep
      carrierList: [],
    }

    super(options, defaults)

    this._fromConfig = this.constructor.fromConfig(excludes)
  }

  static fromConfig(excludes = []) {
    const filtered = Object.keys(PortalUser.formFields())
      .filter(key => !excludes.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: PortalUser.formFields()[key],
        }
      }, {})

    return filtered
  }

  static formFields() {
    return {
      firstName: {
        component: 'BaseInput',
        label: 'First Name',
        halfWidth: true,
        validations: {
          required: {
            params: null,
            message: 'First Name is required',
          },
        },
      },
      lastName: {
        component: 'BaseInput',
        label: 'Last Name',
        halfWidth: true,
        validations: {
          required: {
            params: null,
            message: 'Last Name is required',
          },
        },
      },
      email: {
        component: 'BaseInput',
        label: 'Email',
        placeholder: 'me@example.com',
        validations: {
          required: {
            params: null,
            message: 'Email is required',
          },
          email: {
            params: null,
            message: 'Not a valid email address',
          },
        },
      },
      password: {
        component: 'BaseInput',
        label: 'Password',
        type: 'password',
        validations: {
          required: {
            params: null,
            message: 'Password is required',
          },
          minLength: {
            params: 8,
            message: 'Password must have at least 8 characters',
          },
        },
      },
      passwordConfirmation: {
        component: 'BaseInput',
        label: 'Password Confirmation',
        type: 'password',
        validations: {
          sameAsPassword: {
            params: 'password',
            message: 'Confirm Password must match password',
            func: { validator: 'sameAs', sameAsField: 'password' },
          },
        },
      },
      admin: {
        component: 'BaseCheckBox',
        label: 'Grant portal admin privileges',
        validations: {},
      },
    }
  }
}

export { PortalUser as default }
