import Vue from 'vue'

import VueMeta from 'vue-meta'
Vue.use(VueMeta, { refreshOnceOnNavigation: true })

import { VueSvgIcon } from '@yzfe/vue-svgicon'
Vue.component('icon', VueSvgIcon)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueMask from 'v-mask'
Vue.use(VueMask)

import notyf from '@/config/notyf'
Vue.use(notyf)
