<template lang="pug">
button#back-btn(@click="goBack")
  icon(data="@icon/navigate-before.svg" width="24" height="24")
  | Home
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    goBack,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function goBack() {
  this.$router.push({ name: 'home' })
}
</script>
