<template lang="pug">
#login-view
  h1 Login
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  // created() {
  //   this.$auth.loginWithRedirect({ redirect_uri: 'http://localhost:8082/auth-callback' })
  // },

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
