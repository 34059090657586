import BaseModel from './base'

class CurrentUser extends BaseModel {
  constructor(options) {
    const defaults = {
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      admin: false,
      carrierId: null,
      lastSeenAt: null,
      carrierList: [],
    }

    super(options, defaults)
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }
}

export { CurrentUser as default }
