import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default {
  install(Vue, options) {
    const notyf = new Notyf({
      dismissible: true,
      duration: 2000,
    })

    Vue.notyf = notyf
    Vue.prototype.$notyf = notyf
  },
}
