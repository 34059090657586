import Vue from 'vue'
import PortalUser from '@/models/portalUser'

export const state = {
  users: [],
}

export const getters = {}

export const mutations = {
  ADD(state, data) {
    const index = state.users.findIndex(u => u.id == data.id)

    if (index == -1) state.users.push(new PortalUser(data))
  },

  APPEND(state, users) {
    users.forEach(user => {
      const index = state.users.findIndex(i => i.id == user.id)

      if (index == -1) state.users.push(new PortalUser(user))
    })
  },

  SET(state, users) {
    state.users = users.map(u => new PortalUser(u))
  },

  UPDATE(state, data) {
    const user = state.users.find(u => u.id == data.id)

    if (user) {
      Object.assign(user, new PortalUser(data))
    }
  },
}

export const actions = {
  async create({ commit }, data) {
    try {
      const response = await Vue.axios.post('/users', { user: data })

      commit('ADD', response.data)

      return response
    } catch (error) {
      if (error.response.status == 500) {
        Vue.notyf.error('Something went wrong. Please try again.')
      } else {
        Vue.notyf.error(error.response?.data?.description)
      }

      return error.response
    }
  },

  async update({ commit }, data) {
    try {
      const response = await Vue.axios.put(`/users/${data.id}`, { user: data })

      commit('UPDATE', response.data)

      return response
    } catch (error) {
      if (error.response.status == 500) {
        Vue.notyf.error('Something went wrong. Please try again.')
      } else {
        Vue.notyf.error(error.response?.data?.description)
      }

      return error.response
    }
  },

  async fetch({ commit }, { page, sort }) {
    try {
      const response = await Vue.axios.get(`/users?page=${page}&sort=${sort}`)

      if (page == 1) {
        commit('SET', response.data?.users)
      } else {
        commit('APPEND', response.data?.users)
      }

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
