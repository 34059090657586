import * as vuelidateValidators from 'vuelidate/lib/validators'
import * as kitValidators from '@/validators'

const validators = { ...kitValidators, ...vuelidateValidators }

export const validationRules = (schema, vm) => {
  return Object.keys(schema).reduce((rules, elementName) => {
    const item = schema[elementName]

    if (!('validations' in item)) return rules

    const validations = {}

    for (const rule in item.validations) {
      const params = item.validations[rule].params
      const func = item.validations[rule]?.func

      if (func) {
        const validatorFunc = item.validations[rule].func.validator
        const sameAsField = item.validations[rule].func.sameAsField

        validations[validatorFunc] = validators[validatorFunc](sameAsField, vm)
      } else if (params) {
        validations[rule] = validators[rule](params, vm)
      } else {
        validations[rule] = validators[rule]
      }
    }

    rules[elementName] = validations

    return rules
  }, {})
}
