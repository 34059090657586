import Vue from 'vue'
import Sku from '@/models/sku'

export const state = {
  skus: [],
  currentSku: null,
}

export const getters = {
  getCurrentSku: state => {
    return state.currentSku
  },
}

export const mutations = {
  ADD(state, data) {
    const index = state.skus.findIndex(s => s.medplumName == data.medplumName)

    if (index == -1) state.skus.push(new Sku(data))
  },

  APPEND(state, skus) {
    skus.forEach(sku => {
      const index = state.skus.findIndex(i => i.medplumName == sku.medplumName)

      if (index == -1) state.skus.push(new Sku(sku))
    })
  },

  SET(state, skus) {
    state.skus = skus.map(sku => new Sku(sku))
  },

  SET_CURRENT(state, sku) {
    state.currentSku = sku
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
