import { authenticationGuard } from '@/plugins/authenticationGuard'

export default [
  {
    path: '/',
    name: 'home',
    meta: { layout: 'default', auth: false },
    beforeEnter: authenticationGuard,
    component: require('@/views/dashboard/DashBoard').default,
  },
  {
    path: '/auth/callback',
    name: 'callback',
    meta: { layout: 'default' },
    beforeEnter: authenticationGuard,
    component: require('@/views/sessions/AuthCallback').default,
  },
  {
    path: '/orders/create',
    name: 'createOrder',
    meta: { layout: 'default', auth: false },
    beforeEnter: authenticationGuard,
    component: require('@/views/orders/OrderCreate').default,
  },
  {
    path: '/orders/create/success',
    name: 'createOrderSuccess',
    meta: { layout: 'default', auth: false },
    beforeEnter: authenticationGuard,
    component: require('@/views/orders/SingleOrderCreated').default,
  },
  {
    path: '/orders/create-batch-order',
    name: 'createBatchOrder',
    meta: { layout: 'default', auth: false },
    beforeEnter: authenticationGuard,
    component: require('@/views/orders/OrderBatchCreate').default,
  },
  {
    path: '/orders/review-batch-order',
    name: 'reviewBatchOrder',
    meta: { layout: 'default' },
    props: true,
    beforeEnter: authenticationGuard,
    component: require('@/views/orders/OrderBatchReview').default,
  },
  {
    path: '/orders/create-batch-order/success/:orderCount?',
    name: 'createBatchOrderSuccess',
    meta: { layout: 'default', auth: false },
    beforeEnter: authenticationGuard,
    component: require('@/views/orders/BatchOrderCreated').default,
    props: true,
  },
  {
    path: '/orders',
    name: 'viewOrders',
    meta: { layout: 'default', auth: false },
    beforeEnter: authenticationGuard,
    component: require('@/views/orders/OrderViews').default,
  },
  {
    path: '/admin/carriers',
    name: 'carriersView',
    meta: { layout: 'default', auth: false },
    beforeEnter: authenticationGuard,
    component: require('@/views/admin/CarriersView').default,
  },
  {
    path: '/admin/users',
    name: 'usersView',
    meta: { layout: 'default', auth: false },
    beforeEnter: authenticationGuard,
    component: require('@/views/admin/UsersView').default,
  },
  {
    path: '/admin/orders',
    name: 'adminViewOrders',
    meta: { layout: 'default' },
    beforeEnter: authenticationGuard,
    component: require('@/views/admin/OrdersView').default,
  },
  {
    path: '/design',
    name: 'design',
    meta: { layout: 'default', auth: false },
    beforeEnter: authenticationGuard,
    component: require('@/views/reference/DesignSystem').default,
  },
]
