import Vue from 'vue'
import Carrier from '@/models/carrier'

export const state = {
  carriers: [],
  current: null,
}

export const getters = {}

export const mutations = {
  ADD(state, data) {
    const index = state.carriers.findIndex(c => c.id == data.id)

    if (index == -1) state.carriers.push(new Carrier(data))
  },

  APPEND(state, carriers) {
    carriers.forEach(carrier => {
      const index = state.carriers.findIndex(i => i.id == carrier.id)

      if (index == -1) state.carriers.push(new Carrier(carrier))
    })
  },

  SET(state, carriers) {
    state.carriers = carriers.map(carrier => new Carrier(carrier))
  },

  SET_CURRENT(state, carrier) {
    state.current = carrier
  },
}

export const actions = {
  async create({ commit }, data) {
    try {
      const response = await Vue.axios.post('/carriers', { carrier: data })

      commit('ADD', response.data)

      return response.data
    } catch (error) {
      if (error.response.status == 500) {
        Vue.notyf.error('Something went wrong. Please try again.')
      }

      return error.response
    }
  },

  async fetch({ commit }, { page, sort }) {
    try {
      const response = await Vue.axios.get(`/carriers?page=${page}&sort=${sort}`)

      if (page == 1) {
        commit('SET', response.data?.carriers)
      } else {
        commit('APPEND', response.data?.carriers)
      }

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
