import { helpers } from 'vuelidate/lib/validators'
import { AsYouType } from 'libphonenumber-js'

export default value => {
  if (!value) return !helpers.req(value)

  try {
    const asYouTypeUS = new AsYouType('US')
    const asYouTypePR = new AsYouType('PR')
    asYouTypeUS.input(value)
    asYouTypePR.input(value)
    return asYouTypeUS.getNumber().isValid() || asYouTypePR.getNumber().isValid()
  } catch (error) {
    return false
  }
}
