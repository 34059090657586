import Vue from 'vue'

import app from '@/plugins/app'
Vue.use(app)

import breakpoint from '@/plugins/breakpoint'
Vue.use(breakpoint)

import modal from '@/plugins/modal'
Vue.use(modal)

import panel from '@/plugins/panel'
Vue.use(panel)
