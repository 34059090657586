export default [
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'sessions' },
    component: require('@/views/sessions/Login').default,
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { layout: 'sessions' },
    component: require('@/views/sessions/Logout').default,
  },
]
