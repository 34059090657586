import BaseModel from './base'

class Carrier extends BaseModel {
  constructor(options) {
    const defaults = {
      id: null,
      name: null,
      identifier: null,
      ordersCount: 0,
      usersCount: 0,
      isKit: false,
      createdAt: null,
    }

    super(options, defaults)

    this._fromConfig = this.constructor.fromConfig()
  }

  static fromConfig() {
    return {
      name: {
        component: 'BaseInput',
        label: "Name (ie: 'Fabric')",
        placeholder: 'Name',
        validations: {
          required: {
            params: null,
            message: 'Name is required',
          },
        },
      },
      identifier: {
        component: 'BaseInput',
        label: "Identifier (ie: 'FAB')",
        placeholder: 'Identifier',
        validations: {
          required: {
            params: null,
            message: 'Identifier is required',
          },
          minLength: {
            params: 3,
            message: 'Minimum of 3 characters',
          },
          maxLength: {
            params: 6,
            message: 'Maximum of 6 characters',
          },
        },
      },
    }
  }
}

export { Carrier as default }
