<template lang="pug">
.app-panel-wrapper
  .app-panel(v-for="(panel, index) in panels" :key="panel.componentPath")
    .app-panel-bg
    keep-alive
      component.panel-content(
        :is="loadedComponents[index]"
        v-bind="panel.props"
      )
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {},

  data() {
    return {}
  },

  computed: {
    ...mapState({
      panels: state => state.panel.panels,
    }),
    loadedComponents,
  },

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
function loadedComponents() {
  return this.panels.map(panel => {
    return () => import(`@/components/panels/${panel.componentPath}`)
  })
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
