<template lang="pug">
#top-nav
  CarrierSelect
  #logout(@click="logout") Log out
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    logout,
  },

  components: {
    CarrierSelect: require('@/components/CarrierSelect').default,
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function logout() {
  // this.$auth.logout()
  this.$auth.logout({
    returnTo: window.location.origin,
  })
}
</script>
