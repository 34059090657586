<template lang="pug">
#order-views
  BackButton

  .order-view-container
    .page-title-block
      h1 Orders Status
      //- .search
      //-   icon.search-icon(data="@icon/search.svg" width="32" height="32" color="#3f3f3f")
      //-   input(@keyup.enter="searchOrders" type="text" placeholder="Search...")

    .page-content-block
      #page-info {{ paginationInfo }}

      .table-wrapper
        export-excel(:fetch="getOrders" :fields="csv_fields" type="csv" name="all_orders.csv")
          a Export all orders to CSV
        vue-good-table#order-status-table(:columns="columns" :rows="orders" :sort-options="{enabled: false}" styleClass="vgt-table")
          template(slot="table-row" slot-scope="props")
            span(v-if="props.column.field == 'status'")
              span {{ props.formattedRow['status'] }}
              span.status-reason(v-if="props.formattedRow['status'] == 'ORDER CANCELLED'") Reason: {{ props.formattedRow['statusReason'] }}
              span.status-reason(v-if="props.formattedRow['outboundShippingStatus']")
                a(:href="props.formattedRow['outboundShippingStatus']" target="_blank") Outbound Tracking Info
              span.status-reason(v-if="props.formattedRow['inboundShippingStatus']")
                a(:href="props.formattedRow['inboundShippingStatus']" target="_blank") Inbound Tracking Info
            span(v-else-if="props.column.field == 'buttons'")
              button.btn-view-order(@click="viewOrder(props.row)") View
              a#download-report(v-if="reportReady(props.row)" @click="fetchReports(props.row)")
                icon(data="@icon/download.svg")
                | Report
            span(v-else) {{ props.formattedRow[props.column.field] }}

        button.show-more(v-if="showMore" @click="fetchMore") Show more
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  props: {},

  data() {
    return {
      pageInfo: {
        total: 0,
        currentPage: 0,
        startIndex: 0,
        endIndex: 0,
      },
      currentPage: 0,
      showMore: false,
      sortOrder: 'desc',
      readyStatuses: new Set(['LAB RESULTS SENT', 'RESULTS AVAILABLE']),
      columns: [
        {
          label: 'Order ID',
          field: 'orderId',
          tdClass: 'order-id',
        },
        {
          label: 'Customer',
          field: 'patient',
          formatFn: this.getCustomerName,
        },
        {
          label: 'Date Ordered',
          field: 'orderedDateTime',
          type: 'date',
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss'Z'",
          dateOutputFormat: 'yyyy-MM-dd',
        },
        {
          label: 'Order Status',
          field: 'status',
        },
        {
          label: 'Status Reason',
          field: 'statusReason',
          hidden: true,
        },
        {
          field: 'inboundShippingStatus',
          hidden: true,
        },
        {
          field: 'outboundShippingStatus',
          hidden: true,
        },
        {
          label: 'Status Date',
          field: 'statusDateTime',
          type: 'date',
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss'Z'",
          dateOutputFormat: 'yyyy-MM-dd',
        },
        {
          label: '',
          field: 'buttons',
          width: '280px',
        },
      ],
      csv_fields: {
        'Order ID': 'orderId',
        'SKU': 'sku',
        'Patient First Name': 'patient.firstName',
        'Patient Last Name': 'patient.lastName',
        'Patient Birth Date': 'patient.birthDate',
        'Order Status': 'medplumStatus',
        'Order Status Datetime': 'statusDateTime',
        'Order Status Reason': 'statusReason',
        'Outbound Shipping Status': 'outboundShippingStatus',
        'Inbound Shipping Status': 'inboundShippingStatus',
      },
    }
  },

  computed: {
    ...mapState({
      orders: state => state.orders.orders,
    }),
    paginationInfo,
  },

  watch: {
    '$app.carrier': {
      immediate: true,
      async handler(newValue) {
        this.fetchRecords()
      },
    },
  },

  beforeDestroy() {
    this.$store.commit('orders/CLEAR')
  },

  methods: {
    getOrders,
    fetchRecords,
    searchOrders,
    fetchMore,
    viewOrder,
    reportReady,
    fetchReports,
    getCustomerName,
  },

  components: {
    BackButton: require('@/components/BackButton').default,
    VueGoodTable,
  },

  metaInfo() {
    return {
      title: 'Order status',
    }
  },
}

/* Computed ---------------------------------------------------- */
function paginationInfo() {
  const start = this.pageInfo.total == 0 ? 0 : this.pageInfo.startIndex
  const end = this.pageInfo.total == 0 ? 0 : this.pageInfo.endIndex

  // the orders view pagination using medplum v2 is a lot different,
  // but the logic for pagination is shared among portal users and portal carriers.
  // easier to cut this for now.
  // return `Viewing ${start} - ${end} of ${this.pageInfo.total} items`
  return ''
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function fetchMore() {
  this.pageInfo.currentPage++
  this.fetchRecords()
}

async function getOrders() {
  const response = await this.$store.dispatch('orders/fetch', {
    page: 0,
    pageSize: 1000,
    sort: this.sortOrder,
  })

  return response?.data?.orders
}

async function fetchRecords() {
  const response = await this.$store.dispatch('orders/fetch', {
    page: this.pageInfo.currentPage,
    pageSize: 25,
    sort: this.sortOrder,
  })

  this.pageInfo = { ...response?.data?.meta }

  // if the next page exists, show the show more button
  // this is to interop with medplum v2 pagination.
  // for if the next property doesn't exist, we'll use the old pagination method
  // (so i can push this ahead of time without breaking things)
  if (this.pageInfo.next !== undefined) {
    this.showMore = this.pageInfo.next
  } else {
    this.showMore = this.pageInfo.endIndex < this.pageInfo.total
  }
}

function searchOrders() {
  console.log('OrderView.vue :107', 'TODO: search orders')
}

function getCustomerName(value) {
  return `${value['firstName']} ${value['lastName']}`
}

function viewOrder(order) {
  // hack to fix the hack
  // get order from orders using the id inside the row
  const orderId = order['orderId']
  const orderToView = this.orders.find(o => o.orderId === orderId)

  this.$panel.open('EditOrder', { order: orderToView, readOnly: true })
}

function reportReady(order) {
  return this.readyStatuses.has(order.status)
}
async function fetchReports(order) {
  try {
    const response = await Vue.axios.get(`/reports?orderId=${order.id}`)
    const url = response?.data?.url
    if (url) {
      window.open(url)
    }

    return response
  } catch (error) {
    console.log(error)
    Vue.notyf.error('Something went wrong fetching the report. Please try again.')
    return error.response
  }
}
</script>
