<template lang="pug">
.form-field(:class="formFieldCss")
  label {{ label }}
  select(:disabled="disabled" @change="$emit('input', $event.target.value)")
    option(
      v-for="opt in options"
      :key="opt.value"
      :value="opt.value"
      :selected="opt.value === value"
    ) {{ opt.label }}
  .form-error(v-if="hasError && error") {{ error }}
</template>

<script>
export default {
  props: {
    value: { type: [String, Number] },
    name: { type: String, required: true },
    label: { type: String, required: true },
    error: { type: String, required: false },
    disabled: { type: Boolean, default: false },
    halfWidth: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
    options: {
      type: Array,
      required: true,
      validator(opts) {
        return (
          opts.find(opt => {
            return !('label' in opt) || !('value' in opt)
          }) === undefined
        )
      },
    },
  },

  data() {
    return {}
  },

  computed: {
    formFieldCss,
  },

  watch: {},

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
function formFieldCss() {
  return {
    'field-error': this.hasError,
    'half-width': this.halfWidth,
  }
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
