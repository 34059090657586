import Vue from 'vue'
import CurrentUser from '@/models/currentUser'

export const state = {
  user: null,
  loaded: false,
}

export const getters = {}

export const mutations = {
  SET(state, user) {
    state.loaded = false
    state.user = new CurrentUser(user)
    state.loaded = true
  },
}

export const actions = {
  async fetch({ commit }) {
    try {
      const response = await Vue.axios.get('/profile')

      const { user, carriers, skus } = response.data
      let currentCarrier = carriers.find(c => c.identifier === 'KIT')
      if (!currentCarrier) {
        currentCarrier = carriers[0]
      }

      const currentSku = skus.find(s => s.medplumName === currentCarrier.identifier)

      console.log(skus)

      commit('SET', user)
      commit('carriers/SET_CURRENT', currentCarrier, { root: true })
      commit('carriers/SET', carriers, { root: true })
      commit('skus/SET', skus, { root: true })
      commit('skus/SET_CURRENT', currentSku, { root: true })

      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  async sync({ commit }, data = {}) {
    try {
      const response = await Vue.axios.post('/profile', { order: data })

      // commit('ADD', response.data)

      return response.data
    } catch (error) {
      console.log(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
