<template lang="pug">
#order-create-batch
  BackButton

  #order-create-container
    #order-create-dragover-background
      icon.upload-file-image(data="@icon/upload.svg" width="201" height="201")

    #order-create-dropzone(ref="dropzone")
      icon.batch-order-image(data="@icon/create-batch-order-bg.svg" width="277" height="201")

      h1 Let’s create a batch order.
      h1.drag-and-drop {{ dragAndDropInstructions }}

  a.download-template(download="kit-batch-order-template.csv" href="/csv/kit-batch-order-template.csv")
    button
      icon.download-icon(data="@icon/download.svg")
      | Download the batch order CSV template
</template>

<script>
import Dropzone from 'dropzone'
import { mapState } from 'vuex'
import Papa from 'papaparse'
import Patient from '@/models/patient'
import Order from '@/models/order'
import OrderValidation from '@/mixins/orderValidation'
import { AsYouType } from 'libphonenumber-js'

export default {
  mixins: [OrderValidation],
  props: {},

  data() {
    return {
      dropzone: null,
      dragAndDropInstructions: '',
      form: null,
    }
  },

  computed: {
    ...mapState({
      currentSku: state => state.skus.currentSku,
    }),
  },

  mounted() {
    this.dropzone = new Dropzone(this.$refs.dropzone, {
      url: '/',
      autoProcessQueue: false,
      createImageThumbnails: false,
    })

    this.dropzone.on('addedfile', file => {
      const data = []

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: header => {
          return header
            .replace('address1', 'line')
            .replace('address2', 'line1')
            .replace('zipCode', 'postalCode')
        },

        step: row => {
          const errors = {}

          const asYouType = new AsYouType('US')
          asYouType.input(row.data.phone)

          row.data.phone = asYouType.getNumber().number
          if(row.data.sku.length === 0 && this.currentSku && this.currentSku.skus.length > 0) {
            row.data.sku = this.currentSku.skus[0].id
          }

          this.form = new Patient(row.data)
          
          this.setMethod(this.form)

          data.push(this.form)

          Object.keys(Patient.defaultOptions()).forEach(key => {
            if (this.$v.form[key]?.$error) errors[key] = true
          })

          this.form.errors = errors
        },
        complete: (result, file) => {
          this.$store.commit('orders/SET_REVIEW_ORDERS', data)
          this.$router.push({ name: 'reviewBatchOrder' })
        },
      })
    })
  },

  watch: {
    '$breakpoint.isTouchDevice': {
      immediate: true,
      async handler(newValue) {
        await this.$nextTick()

        this.dragAndDropInstructions = newValue
          ? 'Tap anywhere in this box to pick a file.'
          : 'Drag & drop your CSV file here, or click anywhere in this box to choose a file.'
      },
    },
  },

  methods: {
    setMethod,
  },

  components: {
    BackButton: require('@/components/BackButton').default,
  },

  metaInfo() {
    return {
      title: 'Create batch order',
    }
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function setMethod(form) {
  this.$v.$touch()
  // This triggers errors in $v.form
  this.$v.form.$anyError
}
</script>
