import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import defaultRoutes from '@/router/defaultRoutes'
// import errorRoutes from '@/router/errorRoutes'
import sessionRoutes from '@/router/sessionRoutes'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [...sessionRoutes, ...defaultRoutes]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
