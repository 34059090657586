<template lang="pug">
  #default-layout
    template(v-if="loaded")
      TopNav
      RouterView.app-view(:key="$route.fullPath")
      AppFooter
      AppPanel(v-if="$panel.visible")
</template>

<script>
import Bugsnag from '@bugsnag/js'

export default {
  props: {},

  data() {
    return {
      loaded: false,
    }
  },

  computed: {},

  watch: {
    '$auth.isLoading': {
      immediate: true,
      async handler(loading) {
        if (loading === false && this.$auth.isAuthenticated) {
          await this.$store.dispatch('currentUser/fetch')
          Bugsnag.setUser(this.$app.user.id, this.$app.user.email, this.$app.user.fullName)

          this.loaded = true
        }
      },
    },
  },

  methods: {},

  components: {
    AppPanel: require('@/components/AppPanel').default,
    TopNav: require('@/components/TopNav').default,
    AppFooter: require('@/components/AppFooter').default,
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
