<template lang="pug">
.carrier-logo
  icon(
    :data="logo.data"
    :color="logo.color"
    height="24"
  )
  .carrier-text(v-if="showBackupText && !hasLogo(carrier)") {{ carrier.identifier }}
</template>

<script>
import carrierIcon from '@/assets/svg/carrier.svg'
import kitLogo from '@/assets/svg/logo-kit.svg'
import fabricLogo from '@/assets/svg/logo-fabric.svg'

const carrierLogos = {
  FAB: { data: fabricLogo, color: '#8c36dfff' },
  KIT: { data: kitLogo, color: '#3F3F3F #151515' },
}

const noSVG = { data: carrierIcon }

export default {
  props: {
    carrier: {
      type: Object,
      required: true,
    },
    showBackupText: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    logo,
  },

  watch: {},

  methods: {
    hasLogo,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function logo() {
  return this.hasLogo() ? carrierLogos[this.carrier.identifier] : noSVG
}

function hasLogo() {
  return carrierLogos[this.carrier.identifier]
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
