import { helpers } from 'vuelidate/lib/validators'
import store from '@/store'

export default value => {
  try {
    const currentSku = store.getters["skus/getCurrentSku"].skus.map(s => {
      return s.id
    });
    return currentSku.includes(value)
  } catch (error) {
    return false
  }
}
