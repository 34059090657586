<template lang="pug">
#order-create
  BackButton

  form.create-new-order-form(@submit.prevent="createOrder")
    .page-title-block
      h1 Create New Order

    DynamicForm(
      :schema="[formGroupA, formGroupB]"
      v-model="form"
      @status="saveStatus"
      ref="dynamicForm"
    )


    .form-actions
      button.place-order(:class="{'is-sending': sending}" type="submit" @click.prevent="createOrder") Place Order
        icon.spinner(v-if="sending" data="@icon/spinner.svg" color="white" :fill="false" width="24" height="24")
</template>

<script>
import { mapState } from 'vuex'
import { AsYouType } from 'libphonenumber-js'
import Patient from '@/models/patient.js'

export default {
  props: {},

  data() {
    return {
      skuDropdownSelection: '',
      form: {},
      formGroupANames: ['firstName', 'lastName', 'birthDate', 'sex', 'phone', 'email'],
      formGroupA: {},
      formGroupB: {},
      sending: false,
      genders: [
        { text: 'Female', value: 'F' },
        { text: 'Male', value: 'M' },
      ],
    }
  },

  computed: {
    ...mapState({
      currentSku: state => state.skus.currentSku,
    }),
  },

  watch: {},

  methods: {
    createOrder,
    saveStatus,
  },

  components: {
    BackButton: require('@/components/BackButton').default,
    DynamicForm: require('@/components/DynamicForm').default,
  },

  created() {
    let skuFields = []
    if (this.currentSku) {
      skuFields = this.currentSku.skus.map(s => {
        return { label: s.providerFriendlyDescription, value: s.id }
      })
    }

    let skuDropdown = {
      component: 'BaseSelect',
      label: 'Sku',
      halfWidth: false,
      options: skuFields,
      validations: {
        required: {
          params: null,
          message: 'A SKU is required',
        },
      },
    }

    if (this.currentSku && this.currentSku.skus.length <= 1) {
      skuDropdown.disabled = true
    }

    this.form = { ...new Patient() }

    Object.keys(this.form._fromConfig).forEach(key => {
      if (this.formGroupANames.includes(key)) {
        this.formGroupA[key] = this.form._fromConfig[key]
      } else {
        this.formGroupB[key] = this.form._fromConfig[key]
      }
    })
    this.formGroupB['sku'] = skuDropdown
    this.form.sku = skuFields[0]?.value
    console.log(skuFields)
  },

  metaInfo() {
    return {
      title: 'Create order',
    }
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
async function createOrder() {
  if (this.sending) return
  this.$refs.dynamicForm.validate()

  if (this.status.invalid) {
    return
  }

  this.sending = true

  const form = { ...this.form }

  const asYouType = new AsYouType('US')
  asYouType.input(this.form.phone)

  form.phone = asYouType.getNumber().number

  const response = await this.$store.dispatch('orders/create', form)

  if (response.status == 422) {
    response.data.forEach(errorMessage => {
      if (errorMessage == 'Email Invalid email address') {
        errorMessage = 'Name field should contain First and Last name'
      }
      this.$notyf.error(errorMessage)
    })
  } else if (response.status != 200) {
    this.$notyf.error(response.statusText)
  } else {
    this.$router.push({ name: 'createOrderSuccess' })
  }

  this.sending = false
}

function saveStatus(status) {
  this.status = status
}
</script>
