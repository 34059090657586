<template lang="pug">
.form-field(:class="formFieldCss")
  label {{ label }}
    icon.toggle-password(v-if="type == 'password'" :data="passwordIcon" @click="togglePassword")

    input(
      :type="inputType"
      v-bind="$attrs"
      :value="value"
      :name="name"
      :placeholder="placeholder || label"
      v-mask="mask"
      :disabled="disabled"
      v-on="{...$listeners, input: event => $emit('input', event.target.value)}"
    )

  .form-error(v-if="hasError && error") {{ error }}
</template>

<script>
import visibilityIcon from '@icon/visibility.svg'
import visibilityOffIcon from '@icon/visibility_off.svg'

export default {
  inheritAttrs: false,
  props: {
    type: { type: String, required: false, default: 'text' },
    value: { type: [String, Number] },
    name: { type: String, required: true },
    label: { type: String, required: true },
    placeholder: { type: String, required: false, default: '' },
    error: { type: String, required: false },
    mask: { type: [String, Array], required: false },
    disabled: { type: Boolean, default: false },
    halfWidth: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
  },

  data() {
    return {
      passwordVisible: false,
      visibilityIcon,
      visibilityOffIcon,
      iconMap: {
        visibilityIcon,
        visibilityOffIcon,
      },
    }
  },

  computed: {
    formFieldCss,
    inputType,
    passwordIcon,
  },

  watch: {},

  methods: {
    togglePassword,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function passwordIcon() {
  const vis = this.passwordVisible
    ? this.iconMap['visibilityIcon']
    : this.iconMap['visibilityOffIcon']

  return vis
}

function formFieldCss() {
  return {
    'field-error': this.hasError,
    'half-width': this.halfWidth,
  }
}

function inputType() {
  let type

  if (this.type == 'password' && !this.passwordVisible) {
    return 'password'
  } else {
    return 'text'
  }
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function togglePassword() {
  this.passwordVisible = !this.passwordVisible
}
</script>
