<template lang="pug">
#order-batch-review
  BackButton

  h1 We’ve found {{ orders.length }} orders from the CSV file.
  p Please review your data below.
  hr

  #order-batch-container
    table
      thead
        tr
          th Name
          th Birth Date
          th Sex
          th Phone Number
          th Email
          th Address
          th Sku
          th &nbsp;
      tbody
        tr(v-for="(order, index) in orders" :key="index")
          td.order-name(:class="{'field-error': anyErrors(order, 'name') }")
            | {{ order.firstName }} {{ order.lastName }}
          td(:class="{'field-error': order.errors.birthDate }") {{ order.birthDate}}
          td.order-sex(:class="{'field-error': order.errors.sex }") {{ order.sex }}
          td(:class="{'field-error': order.errors.phone }") {{ order.phone }}
          td(:class="{'field-error': order.errors.email }") {{ order.email }}
          td(:class="{'field-error': anyErrors(order, 'address') }") {{ order.line }}
            span(v-if="order.line1")
              br
              span {{ order.line1 }} {{ order.address}}
            br
            span {{ order.city }}, {{ order.state }}
            br
            span {{ order.postalCode }}
          td(:class="{'field-error': order.errors.sku }") {{ order.sku }}
          td.edit-order
            button.edit-button(@click="showEditPanel(order)") Edit
            icon.remove-order(@click="removeOrder(order)" data="@icon/x.svg" width="16" height="16" title="Remove order")

  .orders-actions
    button.place-orders(@click.prevent="placeOrders" :disabled="disableSave" :class="{'is-sending': sending}") Place {{orders.length}} orders
      icon.spinner(v-if="sending" data="@icon/spinner.svg" color="white" :fill="false" width="24" height="24")
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {},

  data() {
    return {
      sending: false,
    }
  },

  computed: {
    ...mapState({
      orders: state => state.orders.reviewOrders,
    }),
    disableSave,
    ordersCount,
  },

  watch: {},

  methods: {
    anyErrors,
    placeOrders,
    removeOrder,
    showEditPanel,
  },

  components: {
    BackButton: require('@/components/BackButton').default,
  },

  metaInfo() {
    return {
      title: 'Review batch orders',
    }
  },
}

/* Computed ---------------------------------------------------- */
function disableSave() {
  const errors = this.orders.map(o => !!Object.entries(o.errors).length)
  return !!errors.filter(Boolean).length
}

function ordersCount() {
  return this.orders.length
}

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function anyErrors(order, kind) {
  let error = false
  switch (kind) {
    case 'name':
      error = order.errors.firstName || order.errors.lastName
      break
    case 'address':
      error =
        order.errors.line ||
        order.errors.line1 ||
        order.errors.city ||
        order.errors.state ||
        order.errors.postalCode
      break

    default:
      break
  }

  return error
}

async function placeOrders() {
  if (this.sending) return

  this.sending = true

  const response = await this.$store.dispatch('orders/createBatch', this.orders)

  this.sending = false

  if (response.status == 200) {
    this.$router.push({
      name: 'createBatchOrderSuccess',
      params: { orderCount: this.orders.length },
    })
  }
}

function removeOrder(order) {
  this.$store.commit('orders/REMOVE_REVIEW_ORDER', order)
  this.$notyf.success('Order deleted')

  if (this.orders.length == 0) {
    this.$router.push({ name: 'createBatchOrder' })
  }
}

async function showEditPanel(order) {
  this.$panel.open('EditBatchOrder', { order: order })
}
</script>
