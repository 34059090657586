import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG,
  releaseStage: process.env.VUE_APP_KIT_ENV,
  enabledReleaseStages: ['Prod', 'Staging', 'Dogfood'],
  plugins: [new BugsnagPluginVue(Vue)],
})
