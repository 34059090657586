<template lang="pug">
.form-field.checkbox(:class="formFieldCss")
  input(
      :id="inputId"
      type="checkbox"
      v-bind="$attrs"
      v-model="checked"
      :name="name"
      :disabled="disabled"
      @change="handleChange"
    )

  label(:for="inputId") {{ label }}
  .form-error(v-if="hasError && error") {{ error }}
</template>

<script>
export default {
  props: {
    value: { type: [Boolean, String] },
    name: { type: String, required: true },
    label: { type: String, required: true },
    error: { type: String, required: false },
    disabled: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
  },

  data() {
    return {
      checked: false,
    }
  },

  computed: {
    inputId,
    formFieldCss,
  },

  watch: {},

  created() {
    this.checked = this.value
  },

  methods: {
    handleChange,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function formFieldCss() {
  return {
    'field-error': this.hasError,
    'half-width': this.halfWidth,
  }
}

function inputId() {
  return `cb-${this.name}`
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function handleChange(event) {
  this.checked = event.target.checked
  this.$emit('input', this.checked)
}
</script>
