<template lang="pug">
.form
  template(v-if="schemaIsArray")
    .form-block( v-for="(obj, index) in schema" :key="index")
      component(
        v-for="(field, key) in obj" :key="key"
        :name="key"
        :is="field.component"
        v-bind="{...field}"
        :value="value[key]"
        @input="update(key, $event)"
        :error="errorMessages[key]"
        :hasError="$v.form[key].$error"
        :disabled="field.disabled"
      )

  template(v-else)
    component(
      v-for="(field, key) in formatedSchema" :key="key"
      :name="key"
      :is="field.component"
      v-bind="{...field}"
      :value="value[key]"
      @input="update(key, $event)"
      :error="errorMessages[key]"
      :hasError="$v.form[key].$error"
      :disabled="field.disabled"
    )
</template>

<script>
import { validationRules } from '@/utils/validationParser'

export default {
  props: {
    schema: { type: [Array, Object], required: true },
    value: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false },
  },

  data() {
    return {
      form: {},
      formatedSchema: {},
      schemaIsArray: false,
    }
  },

  validations() {
    return { form: validationRules(this.formatedSchema, this) }
  },

  computed: {
    errorMessages,
  },

  watch: {},

  created() {
    this.schemaIsArray = Array.isArray(this.schema)
    this.setForm()
  },

  methods: {
    emitStatus,
    setForm,
    update,
    validate,
  },

  components: {
    BaseCheckBox: require('@/components/BaseCheckBox').default,
    BaseInput: require('@/components/BaseInput').default,
    BaseSelect: require('@/components/BaseSelect').default,
  },
}

/* Computed ---------------------------------------------------- */
function errorMessages() {
  const validations = this.$v.form

  return Object.keys(this.formatedSchema).reduce((messages, key) => {
    const rules = this.formatedSchema[key].validations
    const rulesKeys = Object.keys(this.formatedSchema[key].validations)
    const validator = validations[key]

    if (!validator) return messages

    for (const rule of rulesKeys) {
      // if (validator[rule] !== false) continue
      messages[key] = rules[rule].message
      return messages
    }

    return messages
  }, {})
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function emitStatus() {
  this.$emit('status', {
    invalid: this.$v.$invalid,
  })
}

function setForm() {
  if (this.schemaIsArray) {
    this.formatedSchema = { ...this.schema[0], ...this.schema[1] }
  } else {
    this.formatedSchema = this.schema
  }

  for (const name in this.formatedSchema) {
    this.$set(this.form, name, this.value[name])
  }
}

function update(key, value) {
  this.$set(this.form, key, value)
  this.$v.form[key].$touch()

  this.$emit('input', {
    ...this.value,
    [key]: value,
  })
}

function validate() {
  this.$v.$touch()
  this.emitStatus()
}
</script>
