<template lang="pug">
#carrier-select
  #customer-logo(@click="openDropdown" :class="logoClasses")
    CarrierLogo(:carrier="$app.carrier" :showBackupText="true")

  ul#cs-dropdown(v-if="showDropdown" v-click-outside="closeDropdown")
    <input v-model="carrierLookup" placeholder="Search for a carrier...">
    li.cs-item(
      v-for="carrier in filteredCarriers()"
      @click="selectCarrier(carrier)"
      :key="carrier.id"
    )
      CarrierLogo(:carrier="carrier")
      .cs-name {{ carrier.name }}
        |
        |
        span.identifier ({{carrier.identifier}})
</template>

<script>
import { mapState } from 'vuex'
import Fuse from 'fuse.js'

export default {
  props: {},

  data() {
    return {
      dropdownVisible: false,
      carrierLookup: '',
    }
  },

  computed: {
    ...mapState({
      carriers: state => state.carriers.carriers,
      skus: state => state.skus,
    }),
    showDropdown,
    logoClasses,
  },

  watch: {},

  methods: {
    closeDropdown,
    openDropdown,
    selectCarrier,
    filteredCarriers,
  },

  components: {
    CarrierLogo: require('@/components/CarrierLogo').default,
  },
}

/* Computed ---------------------------------------------------- */
function logoClasses() {
  return {
    'dropdown-visible': this.dropdownVisible,
    'is-clickable': this.carriers.length > 1,
  }
}
function showDropdown() {
  // TODO: test if user is an admin/Kit user
  return this.dropdownVisible
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function closeDropdown() {
  this.dropdownVisible = false
}

function openDropdown() {
  if (this.carriers.length > 1) {
    this.dropdownVisible = true
  }
}

function filteredCarriers() {
  if (this.carrierLookup === '') {
    return this.carriers
  }
  const options = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name', 'identifier'],
  }
  const fuse = new Fuse(this.carriers, options)
  return fuse.search(this.carrierLookup).map(carrier => {
    return carrier.item
  })
}
function selectCarrier(carrier) {
  const [currentSku] = this.skus.skus.filter(sku => sku.medplumName === carrier.identifier)
  this.$store.commit('carriers/SET_CURRENT', carrier)
  this.$store.commit('skus/SET_CURRENT', currentSku)
  this.closeDropdown()
}
</script>
