import Vue from 'vue'
import store from '@/store'

const app = new Vue({
  store,
  computed: {
    user: {
      get: function() {
        return this.$store.state.currentUser.user
      },
      set: function(user) {
        this.$store.commit('currentUser/SET', user)
      },
    },
    userLoaded() {
      return this.$store.state.currentUser.loaded
    },
    carrier: {
      get: function() {
        return this.$store.state.carriers.current
      },
      set: function(user) {
        this.$store.commit('carriers/SET', user)
      },
    },
  },

  methods: {},
})

export default {
  install: function(Vue) {
    Vue.app = app
    Vue.prototype.$app = app
  },
}
