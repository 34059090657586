<template lang="pug">
#app-layout
  component(:is="(this.$route.meta.layout || 'Default') + 'Layout'")
    RouterView(:key="$route.fullPath")
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  metaInfo() {
    return {
      titleTemplate: `Kit Portal | %s`,
      title: 'portal.kit.com',
    }
  },

  computed: {},

  watch: {},

  mounted() {
    this.$breakpoint.build()
    window.addEventListener('resize', this.appHeight)
    this.appHeight()
  },

  methods: {
    appHeight,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function appHeight() {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
</script>
