<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  async created() {
    await this.$auth.user

    if (this.$auth.isAuthenticated && !this.$auth.isLoading) {
      // await this.$store.dispatch('currentUser/sync')
      this.$router.push({ name: 'home' })
    }
  },

  render: () => null,

  methods: {
    handleErrors,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function handleErrors(errors) {
  Object.entries(errors).forEach(([key, value]) => {
    if (key != 'errors') {
      // TODO: Some Toast method
      // this.$notyf.error({ message: `${value}`, duration: 6000 })
    }
  })

  this.$auth.logout()
}
</script>
