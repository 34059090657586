<template lang="pug">
#carriers-view.app-view
  BackButton

  .av-container
    h1.av-title Manage Carriers

    .av-block
      button.create-action(@click.prevent="createCarrier()") Create Carrier

      vue-good-table(:columns="columns" :rows="carriers" :fixed-header="true" styleClass="vgt-table")
        template(slot='table-row', slot-scope='props')
          button.edit-carrier-button.small(v-if="props.column.field == 'action'" @click="editCarrier(props.row)") Edit
</template>

<script>
import { parseISO, formatISO } from 'date-fns'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { mapState } from 'vuex'

export default {
  props: {},

  data() {
    return {
      columns: [
        {
          label: 'Name',
          field: 'name',
          tdClass: 'vgt-bold',
        },
        {
          label: 'Is Kit',
          field: 'isKit',
          width: '2.5rem',
          thClass: 'vgt-right-align',
          tdClass: 'vgt-right-align',
        },
        {
          label: 'Identifier',
          field: 'identifier',
          width: '10.0rem',
          thClass: 'vgt-right-align',
          tdClass: 'vgt-right-align',
        },
        {
          label: '# of ORDERS',
          field: 'ordersCount',
          type: 'number',
          width: '10.0rem',
        },
        {
          label: '# of Users',
          field: 'usersCount',
          type: 'number',
          width: '10.0rem',
        },
        {
          label: 'Created On',
          field: this.formatDate,
          width: '10.0rem',
          thClass: 'vgt-right-align',
          tdClass: 'vgt-right-align',
        },
        // {
        //   label: 'Actions',
        //   field: 'action',
        //   width: '15.0rem',
        //   sortable: false,
        //   thClass: 'vgt-center-align',
        //   tdClass: 'vgt-center-align',
        // },
      ],
    }
  },

  computed: {
    ...mapState({
      carriers: state => state.carriers.carriers,
    }),
  },

  watch: {},

  async created() {
    await this.fetchRecords()
  },

  methods: {
    createCarrier,
    editCarrier,
    formatDate,
    fetchRecords,
  },

  components: {
    BackButton: require('@/components/BackButton').default,
    VueGoodTable,
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function createCarrier() {
  this.$panel.open('CreateCarrier')
}

function editCarrier(carrier) {
  // this.$panel.open('EditCarrier', { carrier })
}

function formatDate(rowObj) {
  return formatISO(parseISO(rowObj.createdAt), { representation: 'date' })
}

async function fetchRecords() {
  const resp = await this.$store.dispatch('carriers/fetch', {
    page: 1,
    sort: 'desc',
  })
}
</script>
