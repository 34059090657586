import Vue from 'vue'

// Layouts --------------------------------------------------
import DefaultLayout from '@/layouts/DefaultLayout.vue'
Vue.component('DefaultLayout', DefaultLayout)

import ErrorsLayout from '@/layouts/ErrorsLayout.vue'
Vue.component('ErrorsLayout', ErrorsLayout)

import SessionsLayout from '@/layouts/SessionsLayout.vue'
Vue.component('SessionsLayout', SessionsLayout)

import InfoLayout from '@/layouts/InfoLayout.vue'
Vue.component('InfoLayout', InfoLayout)
