import { required, email } from 'vuelidate/lib/validators'
import { validBirth, validPhone, validSku } from '@/validators'

const OrderValidation = {
  data() {
    return {
      ise164phone: false,
      e164phoneLength: 12,
      formPhoneLength: 16,
    }
  },

  validations() {
    return {
      form: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        birthDate: {
          required,
          validBirth,
        },
        phone: {
          required,
          validPhone,
        },
        email: {
          required,
          email,
        },
        line: {
          required,
        },
        city: {
          required,
        },
        state: {
          required,
        },
        postalCode: {
          required,
        },
        sex: {
          required,
        },
        sku: {
          required,
          validSku
        }
      },
    }
  },

  computed: {
    firstNameError,
    lastNameError,
    emailError,
    birthDateError,
    sexError,
    phoneError,
    lineError,
    cityError,
    stateError,
    postalCodeError,
  },

  methods: {},
}

/* Computed ---------------------------------------------------- */
function firstNameError() {
  return !this.$v.form.firstName.required && this.$v.form.firstName.$error
}

function lastNameError() {
  return !this.$v.form.lastName.required && this.$v.form.lastName.$error
}

function emailError() {
  const result = { message: '', invalid: false }

  if (!this.$v.form.email.required && this.$v.form.email.$error) {
    result.invalid = !this.$v.form.email.required && this.$v.form.email.$error
    result.message = 'Email is required'
  }

  if (!this.$v.form.email.email) {
    result.invalid = !this.$v.form.email.email
    result.message = 'Not a valid email address'
  }

  return result
}

function birthDateError() {
  const result = { message: '', invalid: false }

  if (!this.$v.form.birthDate.required && this.$v.form.birthDate.$error) {
    result.invalid = !this.$v.form.birthDate.required && this.$v.form.birthDate.$error
    result.message = 'Birthdate is required'
  }

  if (!this.$v.form.birthDate.validBirth && !this.sending) {
    result.invalid = !this.$v.form.birthDate.validBirth
    result.message = 'Not a valid birth date'
  }

  return result
}

function sexError() {
  return !this.$v.form.sex.required && this.$v.form.sex.$error
}

function phoneError() {
  const result = { message: '', invalid: false }

  if (!this.$v.form.phone.validPhone && this.$v.form.phone.$error) {
    result.invalid = true
    result.message = 'Not a valid phone number'
  }

  if (!this.$v.form.phone.required && this.$v.form.phone.$error) {
    result.invalid = true
    result.message = 'Phone number is required'
  }

  return result
}

function lineError() {
  return !this.$v.form.line.required && this.$v.form.line.$error
}

function cityError() {
  return !this.$v.form.city.required && this.$v.form.city.$error
}

function stateError() {
  return !this.$v.form.state.required && this.$v.form.state.$error
}

function postalCodeError() {
  return !this.$v.form.postalCode.required && this.$v.form.postalCode.$error
}

export default OrderValidation
