<template lang="pug">
#app-footer
  a(href="https://kit.com" target="_blank")
    icon.powered-by-kit(data="@icon/powered-by-kit.svg" alt="Powered by Kit" color="#474646 #141415")

  .copyright Copyright &copy; 2021 The Kit Company, Inc. &middot; All Rights Reserved
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
