class BaseModel {
  constructor(options = {}, defaults = {}) {
    this._errors = {}
    let opts = Object.assign({}, defaults, options)

    // Assign options to instance data (using only property names contained
    // in defaults object to avoid copying properties we don't want)
    Object.keys(defaults).forEach(prop => {
      this[prop] = opts[prop]
    })
  }

  static uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    )
  }

  get errors() {
    return this._errors
  }

  set errors(errors) {
    this._errors = errors
  }
}

export { BaseModel as default }
