<template lang="pug">
#batch-order-created.order-created-view
  BackButton

  .order-created-container
    icon.order-img(data="@icon/order-created-success.svg")
    h1 {{ orderCount || 0 }} orders have been created!

    .order-created-actions
      button.view-orders(@click="goOrders") View my orders
</template>

<script>
export default {
  data() {
    return {
      orderCount: this.$route.params.orderCount,
    }
  },

  computed: {},

  watch: {},

  methods: {
    goOrders,
  },

  components: {
    BackButton: require('@/components/BackButton').default,
  },

  metaInfo() {
    return {
      title: 'Batch orders created',
    }
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function goOrders() {
  this.$router.push({ name: 'viewOrders' })
}
</script>
