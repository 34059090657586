import BaseModel from './base'
import Patient from '@/models/patient.js'

class Order extends BaseModel {
  constructor(options) {
    const patient = options?.patient ? new Patient(options?.patient) : new Patient()

    const defaults = {
      id: null,
      orderId: null,
      orderedDateTime: null,
      status: null,
      statusDateTime: null,
      statusReason: null,
      inboundShippingStatus: null,
      outboundShippingStatus: null,
      medplumStatus: null,
      patient: patient,
      sku: null,
    }

    super(options, defaults)
  }

  static defaultOptions() {
    return {
      patient: new Patient(),
    }
  }
}

export { Order as default }
