<template lang="pug">
#order-views
  BackButton

  .order-view-container
    .page-title-block
      h1 Orders Status
      //- .search
      //-   icon.search-icon(data="@icon/search.svg" width="32" height="32" color="#3f3f3f")
      //-   input(@keyup.enter="searchOrders" type="text" placeholder="Search...")

    .page-content-block
      #page-info Viewing {{ pageInfo.startIndex }} - {{ pageInfo.endIndex }} of {{ pageInfo.total }} items

      .table-wrapper
        table#order-status-table
          thead
            tr#table-headers
              th Order ID
              th Customer
              th Date Ordered
              th Order Status
              th &nbsp;
              th &nbsp;

          tbody
            tr(v-for="order in orders" :key="order.id")
              td.order-id {{ order.orderId }}
              td {{ order.patient.firstName }} {{ order.patient.lastName }}
              td {{ formatDate(order) }}
              td {{ order.status }}
              td: button.btn-view-order(@click="viewOrder(order)") View
              td: a#download-report(v-if="reportReady(order)" @click="fetchReports(order)")
                    icon(data="@icon/download.svg")
                    | Report

        button.show-more(v-if="showMore" @click="fetchMore") Show more
</template>

<script>
import { parse, parseISO, formatISO } from 'date-fns'
import { mapState } from 'vuex'
import Order from '@/models/order'

export default {
  props: {},

  data() {
    return {
      pageInfo: {
        total: 0,
        currentPage: 0,
        startIndex: 0,
        endIndex: 0,
      },
      currentPage: 0,
      showMore: false,
      sortOrder: 'desc',
      form: Order.defaultOptions(),
    }
  },

  computed: {
    ...mapState({
      orders: state => state.orders.orders,
    }),
  },

  created() {
    this.fetchRecords()
  },

  beforeDestroy() {
    this.$store.commit('orders/CLEAR')
  },

  methods: {
    fetchRecords,
    formatDate,
    searchOrders,
    fetchMore,
    viewOrder,
    reportReady,
    fetchReports,
  },

  components: {
    BackButton: require('@/components/BackButton').default,
  },

  metaInfo() {
    return {
      title: 'Order status',
    }
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function fetchMore() {
  this.pageInfo.currentPage++
  this.fetchRecords()
}

async function fetchRecords() {
  const response = await this.$store.dispatch('orders/adminFetch', {
    page: this.pageInfo.currentPage,
    sort: this.sortOrder,
  })

  this.pageInfo = { ...response?.data?.meta }
  this.showMore = this.pageInfo.endIndex < this.pageInfo.total
}

function formatDate(orderObj) {
  const date = formatISO(parseISO(orderObj.orderedDateTime), { representation: 'date' })

  return date
}

function searchOrders() {
  console.log('OrderView.vue :107', 'TODO: search orders')
}

function viewOrder(order) {
  this.$panel.open('EditOrder', { order: order, readOnly: true })
}

function reportReady(order) {
  switch (order.status) {
    case 'LAB RESULTS SENT':
      return true
    case 'RESULTS AVAILABLE':
      return true
    default:
      return false
  }
}
async function fetchReports(order) {
  try {
    const response = await Vue.axios.get(`/reports?orderId=${order.id}`)
    const url = response?.data?.url
    if (url) {
      window.open(url)
    }

    return response
  } catch (error) {
    console.log(error)
    Vue.notyf.error('Something went wrong fetching the report. Please try again.')
    return error.response
  }
}
</script>
