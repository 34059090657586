import BaseModel from './base'

class Sku extends BaseModel {
  constructor(options) {
    const defaults = {
      // carrier identifier
      // skus unique to the carrier
      skus: [],
      medplumName: null,
    }

    super(options, defaults)
  }

  static defaultOptions() {
    return {}
  }
}

export { Sku as default }
