import Vue from 'vue'
import Order from '@/models/order'
import Patient from '@/models/patient'

export const state = {
  orders: [],
  reviewOrders: [],
}

export const getters = {}

export const mutations = {
  ADD(state, data) {
    const index = state.orders.findIndex(o => o.id == data.id)

    if (index == -1) state.orders.push(new Order(data))
  },

  APPEND(state, orders) {
    orders.forEach(order => {
      const index = state.orders.findIndex(i => i.id == order.id)

      if (index == -1) state.orders.push(new Order(order))
    })
  },

  CLEAR(state) {
    state.orders = []
  },

  REMOVE_REVIEW_ORDER(state, data) {
    const index = state.reviewOrders.findIndex(o => o.id == data.id)

    if (index !== -1) {
      state.reviewOrders.splice(index, 1)
    }
  },

  SET(state, orders) {
    state.orders = orders.map(order => new Order(order))
  },

  SET_REVIEW_ORDERS(state, orders) {
    state.reviewOrders = orders
  },

  UPDATE_REVIEW_ORDER(state, data) {
    const order = state.reviewOrders.find(o => o.id == data.id)

    if (order) {
      Object.assign(order, new Patient(data))
    }
  },
}

export const actions = {
  async create({ commit }, data) {
    try {
      const response = await Vue.axios.post('/orders', { order: data })
      // commit('ADD', response.data)

      return response
    } catch (error) {
      return error.response
    }
  },

  async createBatch({ commit }, data) {
    try {
      const response = await Vue.axios.post('/batch-orders', { orders: data })

      // commit('ADD', response.data)

      return response
    } catch (error) {
      Vue.notyf.error('Something went wrong. Please try again.')
      return error.response
    }
  },

  async fetch({ commit }, { page, pageSize, sort }) {
    try {
      const response = await Vue.axios.get(`/orders?page=${page}&pageSize=${pageSize}&sort=${sort}`)

      if (page == 0) {
        commit('SET', response.data?.orders)
      } else {
        commit('APPEND', response.data?.orders)
      }

      return response
    } catch (error) {
      Vue.notyf.error('Something went wrong. Please try again.')
      return error.response
    }
  },

  async adminFetch({ commit }, { page, sort }) {
    try {
      const response = await Vue.axios.get(`/admin/orders?page=${page}&sort=${sort}`)

      if (page == 1) {
        commit('SET', response.data?.orders)
      } else {
        commit('APPEND', response.data?.orders)
      }

      return response
    } catch (error) {
      Vue.notyf.error('Something went wrong. Please try again.')
      return error.response
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
