<template lang="pug">
#design-system
  h1 Design system

  .buttons
    button.primary Primary button
    button.primary(:disabled="true") Primary button

  .buttons
    button.secondary Secondary button
    button.secondary(:disabled="true") Secondary button

  .buttons
    button.green Green button
    button.green(:disabled="true") Green button

  .buttons
    button.outline Outline button
    button.outline(:disabled="true") Outline button
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
