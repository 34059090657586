<template lang="pug">
#single-order-created.order-created-view
  BackButton

  .order-created-container
    icon.order-img(data="@icon/order-created-success.svg")
    h1 Your order has been placed

    .order-created-actions
      button.create-another(@click="goCreate") Create another order
      button.view-orders(@click="goOrders") View my orders
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    goCreate,
    goOrders,
  },

  components: {
    BackButton: require('@/components/BackButton').default,
  },

  metaInfo() {
    return {
      title: 'Order created',
    }
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function goCreate() {
  this.$router.push({ name: 'createOrder' })
}

function goOrders() {
  this.$router.push({ name: 'viewOrders' })
}
</script>
